import React, { useState, useEffect } from "react";
import {
  fetchOrders,
  removeOrders,
  getGroupedOrders,
  assignDriverToOrders,
} from "../../utils/fetchOrders";

import { getProductsWeights } from "../../utils/fetchProducts";

import { fetchDrivers } from "../../utils/fetchDrivers";
import { getDuplicates, areThereNotFoundProducts } from "../../utils/utils";
import CustomDatePicker from "../CustomDatePicker";
import { ThreeDot } from "react-loading-indicators";

import { Button, DataTable } from "../../components";

import { IoMdRefresh } from "react-icons/io";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { useNavigate } from "react-router-dom";

import { Card, List } from "antd";
import { MdDragIndicator } from "react-icons/md";

const addTotalWeightToOrders = (orders, productWeights) => {
  return orders.map((order) => {
    const totalWeight = order.products.reduce((total, product) => {
      return (
        total + (productWeights[product.product_id] || 0) * product.quantity
      );
    }, 0);

    return {
      ...order,
      totalWeight,
    };
  });
};

const MakeRouteStep5 = ({ routeDay, callback }) => {
  const navigate = useNavigate();

  const driversTemplate = [
    { driver: "Victor", city: "LA FLORIDA" },
    { driver: "Victor", city: "LA PINTANA" },
    { driver: "Victor", city: "PUENTE ALTO" },
    { driver: "Victor", city: "SAN BERNARDO" },
    { driver: "Junior", city: "LA REINA" },
    { driver: "Junior", city: "LAS CONDES" },
    { driver: "Junior", city: "PROVIDENCIA" },
    { driver: "Junior", city: "SANTIAGO CENTRO" },
    { driver: "Junior", city: "VITACURA" },
    { driver: "Junior", city: "LO BARNECHEA" },
    { driver: "Junior", city: "COLINA" },
    { driver: "Luis", city: "CERRO NAVIA" },
    { driver: "Luis", city: "CONCHALI" },
    { driver: "Luis", city: "CONCHALÍ" }, // borrar después
    { driver: "Luis", city: "HUECHURABA" },
    { driver: "Luis", city: "INDEPENDENCIA" },
    { driver: "Luis", city: "QUILICURA" },
    { driver: "Luis", city: "RECOLETA" },
    { driver: "Ricardo", city: "RENCA" },
    { driver: "Jose", city: "CERRILLOS" },
    { driver: "Rodrigo", city: "ESTACION CENTRAL" },
    { driver: "Rodrigo", city: "ESTACIÓN CENTRAL" },
    { driver: "Rodrigo", city: "LO PRADO" },
    { driver: "Jose", city: "MAIPU" },
    { driver: "Jose", city: "MAIPÚ" }, // borrar despues
    { driver: "Rodrigo", city: "PUDAHUEL" },
    { driver: "Rodrigo", city: "QUINTA NORMAL" },
    { driver: "Belen", city: "EL BOSQUE" },
    { driver: "Belen", city: "LA GRANJA" },
    { driver: "Belen", city: "PEDRO AGUIRRE CERDA" },
    { driver: "Belen", city: "SAN JOAQUIN" },
    { driver: "Belen", city: "SAN JOAQUÍN" },
    { driver: "Belen", city: "SAN MIGUEL" },
    { driver: "Patricio", city: "SAN RAMON" },
    { driver: "Patricio", city: "SAN RAMÓN" }, // borrar despues
    { driver: "Jose", city: "LA CISTERNA" },
    { driver: "Jose", city: "LO ESPEJO" },
    { driver: "Aldo", city: "MACUL" },
    { driver: "Sergio", city: "ÑUÑOA" },
    { driver: "Carlos", city: "PEÑALOLEN" },
    { driver: "Carlos", city: "PEÑAFLOR" },
    { driver: "Carlos", city: "PADRE HURTADO" },
    { driver: "Carlos", city: "BUIN" },
  ];

  // Aldo: ID 27  - Sergio: ID 26
  const driversTemplateMayorista = [
    { driver: "Aldo", city: "LA FLORIDA" },
    { driver: "Aldo", city: "LA PINTANA" },
    { driver: "Aldo", city: "PUENTE ALTO" },
    { driver: "Aldo", city: "SAN BERNARDO" },
    { driver: "Aldo", city: "LA REINA" },
    { driver: "Aldo", city: "LAS CONDES" },
    { driver: "Aldo", city: "PROVIDENCIA" },
    { driver: "Sergio", city: "SANTIAGO CENTRO" },
    { driver: "Aldo", city: "VITACURA" },
    { driver: "Aldo", city: "LO BARNECHEA" },
    { driver: "Aldo", city: "COLINA" }, //??
    { driver: "Sergio", city: "CERRO NAVIA" },
    { driver: "Sergio", city: "CONCHALI" },
    { driver: "Sergio", city: "CONCHALÍ" }, // borrar después
    { driver: "Sergio", city: "HUECHURABA" },
    { driver: "Sergio", city: "INDEPENDENCIA" },
    { driver: "Sergio", city: "QUILICURA" },
    { driver: "Sergio", city: "RECOLETA" },
    { driver: "Sergio", city: "RENCA" },
    { driver: "Sergio", city: "CERRILLOS" },
    { driver: "Sergio", city: "ESTACION CENTRAL" },
    { driver: "Sergio", city: "ESTACIÓN CENTRAL" },
    { driver: "Sergio", city: "LO PRADO" },
    { driver: "Sergio", city: "MAIPU" },
    { driver: "Sergio", city: "MAIPÚ" }, // borrar despues
    { driver: "Sergio", city: "PUDAHUEL" },
    { driver: "Sergio", city: "QUINTA NORMAL" },
    { driver: "Aldo", city: "EL BOSQUE" },
    { driver: "Aldo", city: "LA GRANJA" },
    { driver: "Sergio", city: "PEDRO AGUIRRE CERDA" },
    { driver: "Aldo", city: "SAN JOAQUIN" },
    { driver: "Aldo", city: "SAN JOAQUÍN" },
    { driver: "Aldo", city: "SAN MIGUEL" },
    { driver: "Aldo", city: "SAN RAMON" },
    { driver: "Aldo", city: "SAN RAMÓN" }, // borrar despues
    { driver: "Aldo", city: "LA CISTERNA" },
    { driver: "Sergio", city: "LO ESPEJO" },
    { driver: "Aldo", city: "MACUL" },
    { driver: "Aldo", city: "ÑUÑOA" },
    { driver: "Aldo", city: "PEÑALOLEN" },
    { driver: "Sergio", city: "PEÑAFLOR" },
    { driver: "Sergio", city: "PADRE HURTADO" },
    { driver: "Sergio", city: "BUIN" }, //??
    { driver: "Sergio", city: "LAMPA" }, //??
  ];

  const [data, setData] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(true); // change it to false when data fetched
  const [dataError, setDataError] = useState(null);
  const [refreshPage, setRefreshPage] = useState(false);
  const [groupData, setGroupData] = useState({});
  const [groupDataMayorista, setGroupDataMayorista] = useState({});
  // const [productsWeights, setProductsWeights] = useState({});

  const [ordersMinorista, setOrdersMinorista] = useState(null);
  const [ordersMayorista, setOrdersMayorista] = useState(null);
  const mayoristaValue = 100;

  useEffect(async () => {
    setIsDataLoading(true);
    setDataError(null);
    const fetchData = async () => {
      try {
        const vals = await fetchOrders(null, routeDay);
        const productsWeights = await getProductsWeights();
        // console.log("estas son los pesos");
        setData(vals);
        const drivers = await fetchDrivers();
        setDrivers(drivers);
        console.log("drivers", drivers);

        // Add weights and separate according to mayorista parameter
        const valsWeigth = addTotalWeightToOrders(vals, productsWeights);
        const valsMinorista = valsWeigth.filter(
          (order) => order.totalWeight <= mayoristaValue
        );
        const valsMayorista = valsWeigth.filter(
          (order) => order.totalWeight > mayoristaValue
        );
        setOrdersMinorista(valsMinorista);
        setOrdersMayorista(valsMayorista);

        // Set Group Data for Drag and Drop
        const groupValsMinorista = getGroupedOrders(
          valsMinorista,
          driversTemplate
        );
        const groupValsMayorista = getGroupedOrders(
          valsMayorista,
          driversTemplateMayorista
        );

        setGroupData(groupValsMinorista);
        setGroupDataMayorista(groupValsMayorista);

        // console.log(
        //   "estas son las ordenes que pesan mas de 100",
        //   valsMayorista
        // );
        // console.log(groupValsMinorista);

        setIsDataLoading(false);
      } catch (error) {
        console.log(error);
        setData(null);
        setDataError(error);
        setIsDataLoading(false);
      }
    };
    fetchData();
    callback({
      groupData,
      groupDataMayorista,
      data: data,
      drivers: drivers,
      ordersMinorista,
      ordersMayorista,
    });
  }, [routeDay, refreshPage]);

  // Drag and drop lists

  const calculateGroupSum = (group) => {
    return group.cards.reduce((total, card) => total + card.number, 0);
  };

  const onDragEnd = (result, groupData, setGroupData) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = groupData.groups[source.droppableId];
    const finish = groupData.groups[destination.droppableId];

    const draggedCard = start.cards.find((card) => card.id === draggableId);
    console.log("draggedCard ", draggedCard); // City to move
    console.log("finsish", finish); // Driver to move the city to

    if (start === finish) {
      const newCardIds = Array.from(start.cards);
      newCardIds.splice(source.index, 1);
      newCardIds.splice(destination.index, 0, draggedCard);

      const newGroup = {
        ...start,
        cards: newCardIds,
      };

      const newState = {
        ...groupData,
        groups: {
          ...groupData.groups,
          [newGroup.id]: newGroup,
        },
      };
      console.log("New state ", newState);
      setGroupData(newState);
      return;
    }

    // Moving from one list to another
    const startCardIds = Array.from(start.cards);
    startCardIds.splice(source.index, 1);
    const newStart = {
      ...start,
      cards: startCardIds,
    };

    const finishCardIds = Array.from(finish.cards);
    finishCardIds.splice(destination.index, 0, draggedCard);
    const newFinish = {
      ...finish,
      cards: finishCardIds,
    };

    const newState = {
      ...groupData,
      groups: {
        ...groupData.groups,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };
    setGroupData(newState);
    console.log("groupData", groupData);
  };

  useEffect(() => {
    const callbackObject = {
      groupData,
      groupDataMayorista,
      data: data,
      drivers: drivers,
      ordersMinorista,
      ordersMayorista,
    };
    console.log("this is the callbackobject", callbackObject);
    callback(callbackObject);
  }, [groupData, groupDataMayorista]);

  return (
    <div className="flex-column justify-center p-3">
      <div className="flex w-full">
        <div>
          <button
            className="p-2 h-10 w-10 rounded-full custom-hover-border active:bg-black"
            onClick={() => {
              setRefreshPage(!refreshPage);
            }}
          >
            <IoMdRefresh className="h-full w-full text-[#4048F1]" />
          </button>
        </div>
      </div>
      <div>
        {isDataLoading ? (
          <div className="w-full text-center min-h-72 flex items-center justify-center">
            <ThreeDot
              variant="brick-stack"
              color="#4048F1"
              size="medium"
              text=""
              textColor=""
            />
          </div>
        ) : dataError ? (
          <div>No hay pedidos agendados en esta fecha.</div>
        ) : data.length === 0 ? (
          <div>Todos los pedidos tienen productos en la base de datos</div>
        ) : (
          <>
            <div className="w-full text-xl font-bold text-gray-600">
              Órdenes Minoristas (menor igual 100kg) -{" "}
              {data?.length - ordersMayorista?.length} pedidos
            </div>
            <DragDropContext
              onDragEnd={(result) => onDragEnd(result, groupData, setGroupData)}
            >
              <div className="flex flex-wrap p-4">
                {groupData.groupOrder.map((groupId) => {
                  const group = groupData.groups[groupId];
                  return (
                    <Droppable droppableId={group.id} key={group.id}>
                      {(provided) => (
                        <Card
                          className="w-60 m-3 hover:shadow-m"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          title={
                            <div className="flex justify-between items-center">
                              <h2 className="text-lg font-bold ">
                                {group.title}
                              </h2>
                              <div>
                                Total:{" "}
                                <span className="font-bold">
                                  {calculateGroupSum(group)}
                                </span>
                              </div>
                            </div>
                          }
                        >
                          {group.cards.length >= 0 ? (
                            <List className="">
                              {group.cards.map((card, index) => (
                                <Draggable
                                  draggableId={card.id}
                                  index={index}
                                  key={card.id}
                                >
                                  {(provided) => (
                                    <>
                                      <List.Item
                                        className="bg-white p-4"
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <MdDragIndicator
                                          size={25}
                                          className="opacity-40"
                                        />
                                        <div className="w-full flex justify-between px-4">
                                          <div>{card.content}</div>{" "}
                                          <div className="font-bold">
                                            {card.number}
                                          </div>
                                        </div>
                                      </List.Item>
                                    </>
                                  )}
                                </Draggable>
                              ))}
                            </List>
                          ) : (
                            <div className="text-gray-500 italic text-sm w-full text-center">
                              Sin pedidos asignados
                            </div>
                          )}

                          {provided.placeholder}
                        </Card>
                      )}
                    </Droppable>
                  );
                })}
              </div>
            </DragDropContext>
            {/* <div className="">
              <DataTable
                addButton={false}
                columns={columns}
                data={data}
                columnRowClick={"order_id"}
                callbackRefresh={() => {
                  setRefreshPage(!refreshPage);
                }}
                callbackRemove={(removeElements) =>
                  handleRemoveOrders(removeElements)
                }
              />
            </div> */}
            <div className="w-full text-xl font-bold text-gray-600">
              Órdenes Mayoristas (sobre 100kg) - {ordersMayorista?.length}{" "}
              pedidos
            </div>
            <DragDropContext
              onDragEnd={(result) =>
                onDragEnd(result, groupDataMayorista, setGroupDataMayorista)
              }
            >
              <div className="flex flex-wrap p-4">
                {groupDataMayorista.groupOrder.map((groupId) => {
                  const group = groupDataMayorista.groups[groupId];
                  return (
                    <Droppable droppableId={group.id} key={group.id}>
                      {(provided) => (
                        <Card
                          className="w-60 m-3 hover:shadow-m"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          title={
                            <div className="flex justify-between items-center">
                              <h2 className="text-lg font-bold ">
                                {group.title}
                              </h2>
                              <div>
                                Total:{" "}
                                <span className="font-bold">
                                  {calculateGroupSum(group)}
                                </span>
                              </div>
                            </div>
                          }
                        >
                          {group.cards.length >= 0 ? (
                            <List className="">
                              {group.cards.map((card, index) => (
                                <Draggable
                                  draggableId={card.id}
                                  index={index}
                                  key={card.id}
                                >
                                  {(provided) => (
                                    <>
                                      <List.Item
                                        className="bg-white p-4"
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <MdDragIndicator
                                          size={25}
                                          className="opacity-40"
                                        />
                                        <div className="w-full flex justify-between px-4">
                                          <div>{card.content}</div>{" "}
                                          <div className="font-bold">
                                            {card.number}
                                          </div>
                                        </div>
                                      </List.Item>
                                    </>
                                  )}
                                </Draggable>
                              ))}
                            </List>
                          ) : (
                            <div className="text-gray-500 italic text-sm w-full text-center">
                              Sin pedidos asignados
                            </div>
                          )}

                          {provided.placeholder}
                        </Card>
                      )}
                    </Droppable>
                  );
                })}
              </div>
            </DragDropContext>
          </>
        )}
      </div>
    </div>
  );
};

export default MakeRouteStep5;
